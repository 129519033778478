import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`About Me`}</h1>
    <p>{`I'm Alfonso Villalobos Ulate, Software Engineer from Chihuahua, Chihuahua México`}</p>
    <p>{`I started my programming journey in C# when I was 14 trying to build a theme installer.
At that time I was really into windows customizations and a cousin of mine was studying CS so
I asked him for some help in the programing field he told me to download Visual Studio. Long story
short I didn't get to create my super custom windows theme but, I build some interfaces and
a simple calculator that could make some operations.`}</p>
    <h2>{`Hobbies`}</h2>
    <p>{`Like most of us, I have too many hobbies, because of my father since the day that I was born my first
introduction into sports was baseball at the age of 3 since then I played a bunch of sports
but the one that I end up loving the most is tennis. I have been playing tennis since 13.`}</p>
    <p>{`Another hobby is fixing broken electronics, when I was a teenage boy I used to make good
money with that skill. Also, I play and compose some music when I have the time and inspiration for
I'm not good by any means but I have a good time every time I do that.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      